import { useEffect, useState } from 'react'

import { useMapper, useLead } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

export const useEditInstallments = ({ proposal = {}, setProduct }) => {
  const [loading, setLoading] = useState(false)
  const { offerUuid } = proposal
  const additionalData = proposal?.additionalData ?? {}
  const { installments = [] } = additionalData
  const { mapSimulationsWhenEdit } = useMapper()
  const { changeLeadData } = useLead()

  useEffect(() => {
    changeLeadData({
      previousStep: 'simulation'
    })
  }, [])

  const onSubmit = async ({ payment }) => {
    try {
      setLoading(true)
      const data = {
        value: proposal?.loanTotal,
        installmentsNumber: installments[payment]?.installments_number,
        installmentsValue: installments[payment]?.installments_value
      }

      const newData = await http.proposal.editProposalData(offerUuid, data)
      const formattedData = mapSimulationsWhenEdit(newData)

      setProduct(formattedData)

      goTo('simulation')
      setLoading(false)
    } catch (err) {
      setLoading(false)

      const errorPayload = {
        error: err?.message,
        requestURL: err?.request?.responseURL
      }

      DDPageError({
        name: 'useEditInstallments | editProposal',
        payload: errorPayload
      })
    }
  }

  return { onSubmit, loading }
}
